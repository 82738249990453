@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";

/* ///// General ///// */

html {
  min-height: 100%;
  position: relative;
}
body {
  font-size: 1.3em;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  margin-bottom: 80px;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  color: #696969 !important;
}
nav#mainNav {
  background: whitesmoke;
}

.footer {
  bottom: 0;
  height: 80px;
  position: absolute;
  width: 100%;
  z-index: 1049;
  text-align: center;
  color: #fff;
  background: #90152a;
  padding: 22px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #b31b34;
}

/* ///// Button ///// */

.btn-primary {
  border: none;
  border-radius: 2px;
  font-size: 14px;
  background-color: #e5e5e5;
  color: #b31b34;
}
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  background-color: #f7f7f7;
  color: #b31b34;
  box-shadow: 0px 0px 30px rgba(17, 17, 17, 0.12);
}

/* ///// Red Button / .btnRed ///// */

.btn-primary.btnRed {
  background-color: #b31b34;
  color: white;
}
.btn-primary.btnRed:hover,
.btn-primary.btnRed:not(:disabled):not(.disabled):active,
.btn-primary.btnRed:focus {
  background-color: #da1939;
  color: white;
}

/* ///// Large Button / .btn-lg ///// */

.btn-lg {
  font-size: 17px;
}

/* ///// Page back Button / .btn-lg ///// */

.pageBackBtn {
  margin-top: 5px;
  padding-left: 0;
  background: none;
}
.pageBackBtn:hover,
.pageBackBtn:not(:disabled):not(.disabled):active,
.pageBackBtn:focus {
  color: #da1939;
  background: none;
  box-shadow: none;
}

/* ///// Form / .greyBkg ///// */

.greyBkg {
  padding: 10px 15px;
  border-radius: 3px;
  background-color: #f3f3f3;
}

.greyBkg label {
  font-size: 12px;
}

.greyBkg .form-group {
  margin-bottom: 0.5rem;
}
.greyBkg .form-control {
  border: none;
  border-radius: 3px;
}

/* ///// No information message ///// */

.no_information_message {
  background: #e4e4e4;
  font-size: 12px;
  font-weight: 600;
  margin: 20px 0 10px 0;
  padding: 7px;
  text-align: center;
  text-transform: uppercase;
  color: #b31b34;
}

.panel-body .no_information_message {
  margin: 0px 15px 20px 15px;
}

.no_information_message i.fa {
  font-size: 13px;
}

/* ///// Spinner ///// */

.spinner:before {
  background-color: rgba(49, 37, 37, 0.2);
  border-radius: 6px;
  content: " ";
  left: 50%;
  height: 1000vh;
  width: 1000vw;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.inline-spinner-left .spinner:before,
.inline-field-spinner .spinner:before {
  content: none;
}

.inline-spinner {
  height: 30px;
  position: relative;
}

.inline-spinner-right {
  left: auto !important;
  /* Spin.js does not allow to set a right position, and allways set a 50% left if no custom input is found. */
  right: 25px;
}

.inline-spinner-left {
  right: auto !important;
}

.inline-spinner-center {
  left: 50%;
}

/* ///// Divider ///// */

.divider {
  border-bottom: 1px dashed #cccccc;
  margin-top: -15px;
  margin-right: 15px;
  margin-left: 15px;
}

.title_divider {
  border-bottom: 1px dashed #cccccc;
  display: table;
  width: 100%;
  margin-top: -10px;
  margin-right: 15px;
  margin-left: 15px;
}

.white_divider {
  background-color: white;
}
.white_divider td,
.white_divider + tr td {
  border: none !important;
}

/* ***** Dashboard ***** */

section {
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

h2 {
  margin: 18px 0;
  font-size: 32px;
  color: #707070;
  font-weight: normal;
}

.dashboard-head {
  display: flex;
  align-items: center;
  height: 70px;
  border-radius: 3px;
  background-color: #f3f3f3;
}

.dashboard-head > div:first-of-type {
  display: flex;
  min-width: 70px;
  height: 70px;
  background-color: #b31b34;
  color: white;
  margin-right: 20px;
  border-radius: 3px 0 0 3px;
  font-size: 40px;
  justify-content: center;
}

h3 {
  margin-top: 5px;
  font-size: 24px;
  color: #707070;
  font-weight: normal;
}
h3 > span {
  margin-right: 8px;
}

.dashboard-list {
  min-height: calc(100vh - 373px);
  margin-bottom: 30px;
}

.dashboard-list li {
  margin: 0 15px;
  padding: 10px 0;
  border-bottom: 1px solid #bcbcbc;
  color: #707070;
}
.dashboard-list li:last-of-type {
  border: none;
}
.dashboard-list li h4 {
  display: inline-block;
  margin: 10px 10px 10px 0px;
  font-size: 16px;
  word-break: break-word;
}
.dashboard-list li > div {
  display: flex;
  margin-top: 2.5px;
  font-size: 15px;
}
.dashboard-list li > div > span {
  display: block;
  width: 90px;
  text-align: right;
  margin: 4px 10px 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}
.dashboard-list li > div button > .fa {
  margin-left: 3px;
}

/* Dashboard table */

.dashboard-list .table {
  margin-bottom: 0;
}

.dashboard-list .table tbody {
  border-radius: 3px;
  background-color: #f3f3f3;
}
.dashboard-list .table tbody td {
  vertical-align: middle;
}

.dashboard-list .table td:first-of-type,
.dashboard-list .table th:first-of-type {
  padding-left: 15px;
}
.dashboard-list .table td:last-of-type,
.dashboard-list .table th:last-of-type {
  padding-right: 15px;
  text-align: right;
}

.dashboard-list .table tbody tr:first-of-type td {
  border: none;
}
.dashboard-list .table tbody tr:first-of-type td:first-of-type {
  border-top-left-radius: 3px;
}
.dashboard-list .table tbody tr:first-of-type td:last-of-type {
  border-top-right-radius: 3px;
}
.dashboard-list .table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 3px;
}
.dashboard-list .table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 3px;
}

.dashboard-list .table td:last-of-type button {
  margin-left: 4px;
}

.dashboard-list .table thead th {
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #b31b34;
}

.dashboard-list .table th,
.dashboard-list .table td {
  padding: 15px 0;
  border-top: 1px solid #bcbcbc;
  font-size: 16px;
}

/**/

.form-group.view_as {
  margin-top: -30px;
}
.view_as label {
  font-size: 16px;
  margin-right: 8px;
  color: #b31a34;
}
.view_as select.form-control {
  width: 180px;
}
.row.back_button {
  height: 28px;
}
.row.back_button button {
  color: #b31a34;
  position: relative;
  z-index: 1000;
}

div.surveys-column {
  display: flex;
  flex-direction: column;
}

div.surveys-column div.dashboard-list {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .dashboard-list.list-completed li > div > span {
    width: 150px;
  }
}

/* Dashboard table scroll */

.dashboard-list .tableCont.tableScroll tbody {
  display: block;
  width: 100%;
  height: 60vh;
  overflow: auto;
}
.dashboard-list .tableCont.tableScroll tbody tr,
.dashboard-list .tableCont.tableScroll td,
.dashboard-list .tableCont.tableScroll th {
  display: block;
}
.dashboard-list .tableCont.tableScroll tbody tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

.tableScroll td,
.tableScroll th {
  width: 50%;
  float: left;
}

/* Dashboard msg */

.dashboard-list-msg {
  height: 60vh;
  margin: 51px 0 0 0;
  padding: 15px;
  border-radius: 3px;
  text-align: center;
  font-size: 17px;
  background-color: #f3f3f3;
}

/* ***** Question ***** */

.middle_divider {
  width: 100px;
  border: 1px solid #cccccc;
  text-align: center;
  margin: auto;
}

/***********/
.cb.row {
  background: #f5f5f5;
  border-radius: 3px;
  padding: 20px;
  margin-top: 30px;
}
@-webkit-keyframes check {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes check {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.cb {
  margin: 0 auto;
}
.cb::after {
  clear: both;
  content: "";
  display: table;
}
.cb__item {
  float: left;
  margin-right: 2em;
}
.cb__item:last-child {
  margin-right: 0;
}
.cb__input {
  display: none;
}
.cb__input + label:before {
  content: "\f10c";
  font-family: FontAwesome;
  font-size: 36px;
  position: absolute;
  top: -30px;
  margin: auto;
  text-align: center;
  height: 23px;
  line-height: 0;
  transition: all 0.7s ease;
}

.cb__input:checked + label:before {
  content: "\f192";
  font-family: FontAwesome;
  -webkit-animation: check 0.6s 1;
  animation: check 0.6s 1;
  color: #b73e47;
}

.cb__label {
  position: relative;
  min-width: 33px;
  color: #a9a9a9;
  font-family: Raleway;
  margin-top: 64px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
.cursor {
  cursor: pointer;
}
.cb__label:before {
  content: "";
  position: absolute;
  top: -10em;
  left: 0;
  width: 100%;
  height: 8em;
}

label.cb__label.green::before,
.cb__input:checked + label.green {
  color: green;
}
label.cb__label.red::before,
.cb__input:checked + label.red {
  color: #b73e47;
}
label.cb__label.gray::before,
.cb__input:checked + label.gray {
  color: rgb(168, 168, 168);
}

label.cb__label.big::before {
  font-size: 48px;
}
label.cb__label.medium::before {
  font-size: 38px;
}
label.cb__label.small::before {
  font-size: 28px;
}

.custom_radio_btn {
  width: 40px;
  margin: auto;
}

p.message {
  font-family: raleway;
  color: grey;
}

.write_answer {
  transition: all 0.7s ease;
}
/******  fade in ********/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media (max-width: 767px) {
  .custom_radio_btn {
    width: 100px;
    margin: auto;
    text-align: center;
  }
}
/*** NgBotstrap tooltip  ***/
.tooltip > div {
  animation-name: delayedFadeIn;
  animation-duration: 0.7s; /* Adjust this duration */
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  } /* Set this to 99% for no fade-in. */
  100% {
    opacity: 1;
  }
}
.float-right {
  float: right;
}
a {
  cursor: pointer;
  color: #b31b34 !important;
  font-size: 15px !important;
  text-decoration: none;
  background-color: transparent;
}
.margin-0 {
  margin: 0;
}
.btn-warning {
  color: #fdfdfd;
  background-color: #fd7e14;
  border-color: #fd7e14;
  border-radius: 2px;
  font-size: 14px;
}
.modal-open .modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header h3 {
  color: #811229 !important;
  font-size: 18px;
  text-transform: uppercase;
}
.modal label {
  color: #811229 !important;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 12px !important;
  font-weight: 400;
}
.modal .form-group {
  margin: 0;
}
.form-group angular-mydatepicker {
  width: 100%;
}
::-webkit-input-placeholder {
  font-size: 13px;
}
::-moz-placeholder {
  font-size: 13px;
}
:-ms-input-placeholder {
  font-size: 13px;
}
::placeholder {
  font-size: 13px;
}
.modal .form-control {
  font-size: 14px;
}
.info {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  display: inline-block;
}
.modal-xlg {
  max-width: 950px;
}
.separation {
  margin-top: 10px;
  margin-bottom: 10px;
}
button.separation {
  margin-top: 6px;
  margin-bottom: 6px;
}
th {
  color: #b31b34 !important;
  font-size: 14px !important;
  text-transform: capitalize;
  border: none;
  padding: 0.4rem 0.75rem;
}
td {
  font-size: 16px;
  padding: 0.4rem 0.75rem;
}
table {
  margin: 0;
}
.card-body .form-group label {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}
.card-body .form-group p {
  font-size: 16px;
  color: #b31b34;
  font-weight: 600;
  margin-bottom: 0;
}
.delegate-details .form-group {
  margin-bottom: 0;
}
.card-body {
  padding: 0.5rem;
}
.mar-b-0 {
  margin-bottom: 0 !important;
}
.show_pagination .btn-light.active {
  color: #ffffff !important;
  background-color: #9a4154 !important;
  border-color: #9a4154 !important;
}
.show_pagination {
  text-align: right;
  font-size: 14px;
}
.show_pagination .btn {
  margin: 0 1px 6px 4px;
  padding: 4px 7px;
  font-size: 12px !important;
}
.ngx-pagination a,
.ngx-pagination button {
  color: #0a0a0a;
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
  color: #b31b34 !important;
}
.ngx-pagination .current {
  padding: 0 7px !important;
  background: #811229 !important;
  border-radius: 4px;
}
.ngx-pagination li {
  font-size: 12px !important;
}
.ngx-pagination {
  padding-left: 0;
}
.tr-head {
  background-color: rgb(243, 243, 243);
}
.pointer {
  cursor: pointer;
}
.curser-default {
  cursor: default;
}
.icon-mydpcalendar {
  color: #b31b34 !important;
}
.mydp td.daycell {
  font-size: 14px;
}
.toast-container {
  font-size: 0.7em;
}
srvy-reopen-modal p.text-center.question_survey {
  padding: 32px 0 0 0 !important;
  font-size: 19px !important;
}
srvy-reopen-modal srvy-question p.message {
  font-size: 19px;
  text-align: center;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 5px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  font-size: 14px;
  line-height: 1.2;
}
.ng-value-container {
  line-height: 1;
}
.ng-select .ng-select-container .ng-value-container .ng-input > input,
.ng-value-container .ng-value-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 5px;
  font-size: 14px;
}
.ng-select-opened .ng-value {
  visibility: hidden;
}
.ng-select-opened .ng-value {
  margin-top: 5px;
}
.ng-input {
  top: 2px;
}
.mar-0 {
  margin: 0;
}
.form-control.ng-invalid,
angular-mydatepicker.ng-invalid .mydp,
.ng-select.ng-invalid .ng-select-container {
  border-color: #b31b34 !important;
}
.custom-control-label::before,
.custom-control-label::after {
  top: 0;
}
.custom-control-label::before {
  border: 1px solid #b7babf;
}
.custom-control-label {
  margin-bottom: 0.7rem;
}
table.scroll {
  width: 100%;
}
table.scroll tbody,
table.scroll thead {
  display: block;
}

.scroll thead tr th {
  height: 30px;
}
.scroll thead tr,
.scroll tbody tr {
  width: 100%;
  display: table;
}
.scroll thead tr {
  width: calc(100% - 10px);
}

table.scroll tbody {
  height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-width: 1300px) {
  .container {
    max-width: 100% !important;
  }
}
.error_page {
  padding: 10px;
}
.error-msg i {
  display: block;
  font-size: 0.6em;
}
